/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { CartProduct, CartPromo, Discount, Hardware } from "~/store/interfaces";
import PromotionWrapper from "./promotions/PromotionWrapper";

export default class CartMath {
  // eslint-disable-next-line class-methods-use-this
  // static getMinimumCost(
  //   monthly,
  //   term,
  //   activationFee,
  //   newDevFee,
  //   hardwareCost,
  //   monthlyDiscount,
  //   feesDiscount,
  //   shippingFee
  // ) {
  //   // compute total monthly plus fees
  //   let totalMinCost =
  //     monthly * term + activationFee + newDevFee + hardwareCost + shippingFee;
  //   // minus discounts
  //   totalMinCost = totalMinCost - monthlyDiscount - feesDiscount;
  //   return totalMinCost.toFixed(2);
  // }

  static getMinimumCost(
    products: CartProduct[],
    term: number,
    activePromo: CartPromo,
    freeMonths: number,
    promosWrapper: PromotionWrapper,
    catalogHardware: Hardware[],
    carrier: string
  ): number {

    // set free months to 0 for Uniti in terms of cost computation. contract term must be the same as paid months excluding free month
    let newFreeMonths = 0;
    if (activePromo && activePromo.code && (['ALAND', 'ONESYDNEY', 'SAVE10GD', 'TRIO', 'UNITILIFE', 'EONMIRANDA', 'WELCOMEHOME', 'SANDHURST', 'BEACHHOUSE', 'CEDARWOODS', 'MORRIS', 'ANIKO', 'REFER30', 'STAR', 'MARKER'].includes(activePromo.code))) {
      newFreeMonths = freeMonths;
    }
    const monthly = this.getMonthlyCost(products);
    const activationFee = products.find((p) => p && p.type === "activationFee")
      ? products.find((p) => p && p.type === "activationFee").price
      : 0;
    const ndc = products.find((p) => p && p.type === "ndc")
      ? products.find((p) => p && p.type === "ndc").price
      : 0;
    const hardware =
      products.filter((p) => p && p.type === "hardware").length > 0
        ? products.filter((p) => p && p.type === "hardware")[0].price
        : 0;
    const planPrice = products.find((p) => p && p.type === "plan")
      ? products.find((p) => p && p.type === "plan").price
      : 0;
    const shipping = products.find((p) => p && p.type === "shippingFee")
      ? products.find((p) => p && p.type === "shippingFee").price
      : 0;

    const feesDiscount =
      this.getFeeDiscounts(
        products,
        promosWrapper,
        catalogHardware,
        term,
        carrier
      ) +
      this.getRouterDiscountAfter(
        products,
        activePromo,
        catalogHardware,
        promosWrapper,
        term,
        carrier
      );
    // compute total monthly plus fees
    let totalMinCost =
      monthly * term + activationFee + ndc + hardware + shipping;
    // minus discounts
    totalMinCost =
      totalMinCost -
      this.getAllMonthlyDiscount(
        products,
        activePromo,
        term,
        newFreeMonths,
        planPrice
      ) -
      feesDiscount;
    return totalMinCost;
  }

  static getAllMonthlyDiscount(
    products: CartProduct[],
    activePromo: CartPromo,
    term: number,
    freeMonths: number,
    planPrice: number
  ): number {
    let allMonthlyDiscount =
      this.getMonthlyTotalDiscount(products, activePromo) *
        this.getMonthlyDiscountTerm(activePromo, term) +
      (freeMonths > 0 ? planPrice * freeMonths : 0);

    if (this.getLateMonthlyDiscount(activePromo) && term > 0) {
      if (this.getMonthlyDiscountTerm(activePromo, term) < term) {
        allMonthlyDiscount +=
          Math.abs(this.getLateMonthlyDiscount(activePromo).price) *
          (term - this.getLateMonthlyDiscount(activePromo).actionStart + 1);
      }
    }

    return allMonthlyDiscount;
  }

  static getMonthlyDiscountTerm(activePromo: CartPromo, term: number): number {
    let discountTerm = 0;
    const recurringDiscount =
      activePromo && activePromo.isValid
        ? activePromo.discounts.find(
            (di) => di.name === "RECURRING" && !di.actionStart
          )
        : null;

    if (recurringDiscount) {
      discountTerm = recurringDiscount.actionDuration
        ? recurringDiscount.actionDuration
        : term;
    }

    return discountTerm;
  }

  static getMonthlyTotalDiscount(
    products: CartProduct[],
    activePromo: CartPromo
  ) {
    let totalMonthly = 0;
    let newPlanPrice = products.find((p) => p && p.type === "plan")
      ? products.find((p) => p && p.type === "plan").price
      : 0;
    if (
      activePromo &&
      activePromo.discounts &&
      activePromo.discounts.find(
        (di) => di.name === "RECURRING" && !di.actionStart
      )
    ) {
      totalMonthly -= activePromo.discounts.find(
        (di) => di.name === "RECURRING" && !di.actionStart
      ).price;

      newPlanPrice -= totalMonthly;
    }

    if (
      activePromo &&
      activePromo.discounts &&
      activePromo.discounts.find((di) => di.name === "PERCENT-AFTER")
    ) {
      const pr = parseFloat(
        (
          (activePromo.discounts.find((di) => di.name === "PERCENT-AFTER")
            .price /
            100) *
          newPlanPrice
        ).toFixed(2)
      );
      totalMonthly -= pr;

      if (products.filter((p) => p && p.type === "voice").length) {
        const vr = parseFloat(
          (
            (activePromo.discounts.find((di) => di.name === "PERCENT-AFTER")
              .price /
              100) *
            products.find((p) => p && p.type === "voice").price
          ).toFixed(2)
        );
        totalMonthly -= vr;
      }
    }
    return totalMonthly;
  }

  static getFeeDiscounts(
    products: CartProduct[],
    promosWrapper: PromotionWrapper,
    catalogHardware: Hardware[],
    term: number,
    carrier: string
  ) {
    let tempFeeDiscounts = 0;

    if (
      products.filter((p) => p && p.name === "FREE-ACTIVATION").length &&
      products.find((p) => p && p.type === "activationFee")
    ) {
      tempFeeDiscounts += products
        .filter((p) => p && p.type === "activationFee")
        .find((e) => !!e).price;
    }

    if (
      products.filter((p) => p && p.name === "FREE-NDC").length &&
      products.filter((p) => p && p.type === "ndc").length
    ) {
      tempFeeDiscounts += products
        .filter((p) => p && p.type === "ndc")
        .find((e) => !!e).price;
    }

    if (
      products.filter((p) => p && p.name === "HALF-NDC").length &&
      products.filter((p) => p && p.type === "ndc").length
    ) {
      tempFeeDiscounts += products
        .filter((p) => p && p.type === "ndc")
        .find((e) => !!e).price / 2;
    }

    if (
      products.filter((p) => p && p.type === "hardware").length &&
      products.filter((p) => p && p.name === "FREE-ROUTER").length
    ) {
      const fd = products.filter((p) => p && p.name === "FREE-ROUTER")[0];
      const baseRouter = promosWrapper
        ? promosWrapper.getBaseRouter(
            catalogHardware,
            fd as Discount,
            products.find((p) => p && p.type === "hardware")
          )
        : null;

      if (baseRouter) {
        tempFeeDiscounts += this.getFreeRouterDiscount(
          products.find((p) => p && p.type === "hardware"),
          baseRouter,
          term,
          carrier
        );
      }
    }

    if (
      products.filter((p) => p && p.type === "hardware").length &&
      products.filter((p) => p && p.name === "ROUTER-DISCOUNT").length
    ) {
      const rd = products.filter((p) => p && p.name === "ROUTER-DISCOUNT")[0];
      const hw = products.filter((p) => p && p.type === "hardware")[0];

      if (
        (rd as any).actionApplicableTo &&
        (rd as any).actionApplicableTo.includes(hw.code)
      ) {
        tempFeeDiscounts += hw.price * (rd.price / -100);
      }
      // if discount is on base router only
      else if (
        catalogHardware.length > 1 &&
        (rd as any).actionApplicableTo &&
        (rd as any).actionApplicableTo.find((aa) =>
          products
            .filter((p) => p && p.type === "hardware")[0]
            .code.includes(aa)
        )
      ) {
        const baseRouter = promosWrapper.getBaseRouter(
          catalogHardware,
          rd as Discount,
          products.find((p) => p && p.type === "hardware")
        );
        tempFeeDiscounts += CartMath.getBaseRouterDiscount(
          products.filter((p) => p && p.type === "hardware")[0],
          baseRouter,
          term,
          carrier,
          rd.price
        );
      }
    }

    return tempFeeDiscounts;
  }

  static getLateMonthlyDiscount(activePromo: CartPromo): CartProduct {
    if (
      activePromo &&
      activePromo.discounts &&
      activePromo.discounts.find(
        (di) => di.name === "RECURRING" && di.actionStart > 0
      )
    ) {
      return activePromo.discounts.find(
        (di) => di.name === "RECURRING" && di.actionStart > 0
      );
    }
    return null;
  }

  static getFreeRouterDiscount(
    selectedRouter,
    routerOptions,
    term,
    carrierCode
  ) {
    let routerDiscount = selectedRouter.price;
    routerDiscount = routerOptions.price;

    return routerDiscount;
  }

  static getRouterDiscountAfter(
    products: CartProduct[],
    activePromo: CartPromo,
    catalogHardware: Hardware[],
    promosWrapper: PromotionWrapper,
    term: number,
    carrier: string
  ) {
    let tempFeeDiscounts = 0;
    if (activePromo) {
      if (
        products.filter((p) => p && p.type === "hardware").length &&
        products.filter((p) => p && p.name === "ROUTER-DISCOUNT").length
      ) {
        const rd = products.find((p) => p && p.name === "ROUTER-DISCOUNT");
        const hw = products.find((p) => p && p.type === "hardware");

        if (
          (rd as any).actionApplicableTo &&
          (rd as any).actionApplicableTo.includes(hw.code)
        ) {
          tempFeeDiscounts += hw.price * (rd.price / -100);
        }
        // if discount is on base router only
        else if (
          catalogHardware.length > 1 &&
          (rd as any).actionApplicableTo &&
          (rd as any).actionApplicableTo.find((aa) =>
            products
              .filter((p) => p && p.type === "hardware")[0]
              .code.includes(aa)
          )
        ) {
          const baseRouter = promosWrapper.getBaseRouter(
            catalogHardware,
            rd as Discount,
            products.find((p) => p && p.type === "hardware")
          );
          tempFeeDiscounts += CartMath.getBaseRouterDiscount(
            products.find((p) => p && p.type === "hardware"),
            baseRouter,
            term,
            carrier,
            rd.price
          );
        }
      }
      if (
        products.filter((p) => p && p.type === "hardware").length &&
        products.filter((p) => p && p.name === "ROUTER-DISCOUNT-AFTER").length
      ) {
        const newRouterPrice =
          products.find((p) => p && p.type === "hardware").price -
          tempFeeDiscounts;
        const rd =
          products.find((p) => p && p.name === "ROUTER-DISCOUNT-AFTER").price /
          100;
        const fd = parseFloat((newRouterPrice * rd).toFixed(2));
        return Math.abs(fd);
      }
    }
    return 0;
  }

  static getBaseRouterDiscount(
    selectedRouter,
    routerOptions,
    term,
    carrierCode,
    percentage
  ) {
    let routerDiscount = selectedRouter.price;
    if (routerOptions) {
      routerDiscount = routerOptions.price * (percentage / -100);
    }

    return routerDiscount;
  }

  static getTotalSavings(monthlyDiscount, feesDiscount) {
    const totalSavings = monthlyDiscount + feesDiscount;

    return totalSavings.toFixed(2);
  }

  static getMonthlyCost(products): number {
    const planMonthlyCost = products
      ? products.find((p) => p && p.type === "plan").price
      : 0;
    const voice = products.filter((p) => p && p.type === "voice").length
      ? products.find((p) => p && p.type === "voice").price
      : 0;

    // check for addons
    let addonCost = 0;
    const monthlyAddons = products.filter(
      (p) => p && p.type === "addon" && p.recurrence.includes("M")
    );
    monthlyAddons.map((ma) => {
      addonCost += ma.price;
      return ma;
    });

    const totalMonthlyCost = planMonthlyCost + voice + addonCost;

    return totalMonthlyCost.toFixed(2);
  }
}
